// Injected with inline <script> inside index.html
declare global {
  interface Window {
    _env: { [key: string]: string };
  }
}

const config = {
  api: {
    kratospublic: window._env.REACT_APP_API_KRATOS_PUBLIC,
    frontapplication: window._env.REACT_APP_FRONT_APPLICATION,
  },
  disableLogin: window._env.REACT_APP_DISABLE_LOGIN,
};

export default config;
