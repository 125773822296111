import React from 'react';
import { IconProps } from './IconCheck';

const IconArrowRight = ({ height, width, color }: IconProps): React.ReactElement => (
  <svg width={width ?? '24'} height={height ?? '25'} viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 12.503c0 .318.228.573.508.573l15.598-.001-2.837 2.865a.575.575 0 0 0 0 .81c.22.221.58.221.8 0l3.727-3.759a.59.59 0 0 0 .242-.488.583.583 0 0 0-.279-.508l-3.69-3.727a.56.56 0 0 0-.8 0 .572.572 0 0 0 0 .807l2.831 2.858H3.508c-.28 0-.508.257-.508.57z"
      fill={color ?? '#ffffff'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconArrowRight;
