import React, { ReactElement } from 'react';
import { Image } from 'semantic-ui-react';

import './Maintenance.scss';

const UnavailableScreen = (): ReactElement => {
  return (
    <div className="unavailable-screen">
      <div className="unavailable-screen_section">
        <div className="unavailable-screen_section_text">
          We are currently down <br />
          for maintenance.
        </div>
        <div className="unavailable-screen_section_image">
          <div className="unavailable-screen_section_subtext">We’ll be back shortly. Thanks for your patience.</div>
          <Image src={`${process.env.PUBLIC_URL}/images/maintenance_screen.png`} centered></Image>
        </div>
      </div>
    </div>
  );
};

export default UnavailableScreen;
