import React from 'react';

export interface IconProps {
  height?: number;
  width?: number;
  color?: string;
}
const IconCheck = ({ height, width, color }: IconProps): React.ReactElement => (
  <svg width={width ?? '30'} height={height ?? '30'} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m12.676 21.437-5.51-5.633a.848.848 0 0 1 0-1.185.803.803 0 0 1 1.159 0l4.925 5.04 9.076-9.73a.806.806 0 0 1 1.16 0 .854.854 0 0 1 0 1.186l-9.652 10.322a.824.824 0 0 1-.584.246.796.796 0 0 1-.574-.246z"
      fill={color ?? '#8397FF'}
      fillRule="evenodd"
    />
  </svg>
);

export default IconCheck;
