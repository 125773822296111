import { AxiosError } from 'axios';
import React, { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Divider, Form, Image, Input, Loader } from 'semantic-ui-react';

import { SelfServiceRecoveryFlow, SubmitSelfServiceRecoveryFlowBody, UiNodeInputAttributes } from '@ory/client';
import { handleFlowError } from '../pkg/errors';
import ory from '../pkg/sdk';

import IconCheck from '../components/Icon/IconCheck';

import { mailRegex } from 'utils/validation';
import { colorPrimaryPurple } from 'utils/colors';

const Recovery = (): ReactElement => {
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [isMailSent, setIsMailSent] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [mailError, setMailError] = useState<string | undefined>();
  const [flow, setFlow] = useState<SelfServiceRecoveryFlow>();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  // Get ?flow=... from the URL
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');

  useEffect(() => {
    setIsLoading(true);
    // If the router is not ready yet, or we already have a flow, do nothing.
    if (flow) {
      setIsLoading(false);
      return;
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getSelfServiceRecoveryFlow(String(flowId))
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleFlowError(navigate, 'recovery', setFlow));
      setIsLoading(false);
      return;
    }

    // Otherwise we initialize it
    ory
      .initializeSelfServiceRecoveryFlowForBrowsers()
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleFlowError(navigate, 'recovery', setFlow))
      .catch((err: AxiosError) => {
        // If the previous handler did not catch the error it's most likely a form validation error
        if (err.response?.status === 400) {
          // Yup, it is!
          setFlow(err.response?.data);
          return;
        }
        setIsLoading(false);
        return Promise.reject(err);
      });
    setIsLoading(false);
  }, [flowId, returnTo, flow, navigate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };
  const getCsrftokenFromFlow = (flow: SelfServiceRecoveryFlow): string => {
    const flowAttributes = flow.ui?.nodes[0]?.attributes as UiNodeInputAttributes;
    return flowAttributes?.value;
  };

  const handleSubmit = async (): Promise<void> => {
    if (isMailSent) {
      navigate('/login');
    } else {
      if (flow && email) {
        const values = {
          email,
          method: 'link',
          csrf_token: getCsrftokenFromFlow(flow),
        } as SubmitSelfServiceRecoveryFlowBody;

        setSearchParams(`/recovery?flow=${flow?.id}`, { replace: true });
        return ory
          .submitSelfServiceRecoveryFlow(String(flow?.id), values)
          .then(({ data }) => {
            // Form submission was successful, show the message to the user!
            setFlow(data);
            setIsMailSent(true);
          })
          .catch(handleFlowError(navigate, 'recovery', setFlow))
          .catch((err: AxiosError) => {
            switch (err.response?.status) {
              case 400:
                // Status code 400 implies the form validation had an error
                setFlow(err.response?.data);
                return;
            }
            throw err;
          });
      }
    }
  };

  const onReset = () => {
    setIsMailSent(false);
    setFlow(undefined);
  };

  useEffect(() => {
    if (email && !email.match(mailRegex)) {
      setMailError('Please enter a valid email');
    } else {
      setMailError(undefined);
    }
  }, [email]);

  return (
    <div className="login">
      {isloading ? (
        <Loader active />
      ) : (
        <div className="login-segment">
          <div className="login-logo">
            <Image src={`${process.env.PUBLIC_URL}/images/colored-logo.png`} size="small" centered></Image>
          </div>
          <Divider className="login-divider" />
          {!isMailSent ? (
            <div className="login-message">
              <h3 className="login-message-title">Forgot Password ?</h3>
              <div>
                Enter the email associated with your account.
                <br /> We&apos;ll send you an email with instructions to reset your password.
              </div>
            </div>
          ) : (
            <div className="login-message">
              <div className="login-mail-sent">
                <IconCheck color={colorPrimaryPurple} /> <h3 className="login-message-title">Email have been sent </h3>
              </div>
              <div>
                Please check your inbox and click on the link to reset your password.
                <div className="not-receive-mail-link" onClick={onReset}>
                  Didn&apos;t receive the link ?
                </div>
              </div>
            </div>
          )}

          <Form className="login-form" onSubmit={handleSubmit}>
            {!isMailSent && (
              <Form.Field
                id="email"
                control={Input}
                label="Email Address"
                placeholder="name@email.com"
                value={email}
                onChange={handleChange}
                error={mailError}
              />
            )}
            <div className="login-continue-button">
              <Button className="submit" type="submit" disabled={isMailSent ? false : !email || !!mailError}>
                {isMailSent ? 'Login' : 'Submit'}
                <IconCheck height={23} width={23} color="#ffffff"></IconCheck>
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};

export default Recovery;
