import React, { ReactElement } from 'react';
import { Menu, Image } from 'semantic-ui-react';
import config from 'config';

const Navbar = (): ReactElement => {
  const logo = process.env.PUBLIC_URL + '/images/logo-blue.png';

  return (
    <>
      {config.disableLogin === 'true' && (
        <>
          <Menu text fixed="top">
            <Menu.Item position="left" className="nav-bar--cursor">
              <Image width={100} src={logo} alt="logo" />
            </Menu.Item>
          </Menu>
        </>
      )}
    </>
  );
};

export default Navbar;
