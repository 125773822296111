import { Configuration, V0alpha2Api } from '@ory/client';
import { AxiosRequestConfig } from 'axios';
import config from 'config';

export default new V0alpha2Api(
  new Configuration({
    basePath: config.api.kratospublic,
    baseOptions: {
      withCredentials: true,
    } as AxiosRequestConfig,
  }),
);
