import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { mailRegex } from 'utils/validation';
import config from 'config';
import { Button, Divider, Form, Icon, Image, Input, Loader, Message } from 'semantic-ui-react';
import { SelfServiceLoginFlow, SubmitSelfServiceLoginFlowBody, UiNodeInputAttributes } from '@ory/client';
import { handleGetFlowError, handleFlowError } from '../pkg/errors';
import ory from '../pkg/sdk';
import { AxiosError } from 'axios';
import IconArrowRight from '../components/Icon/IconArrowRight';
import Maintenance from './Maintenance';

import './Login.scss';

interface FormMessage {
  color: 'red' | 'green';
  message: string;
}

const Login = (): ReactElement => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [formMessage, setFormMessage] = useState<FormMessage | undefined>();
  const [mailError, setMailError] = useState<string | undefined>();
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [flow, setFlow] = useState<SelfServiceLoginFlow>();

  const [form, setForm] = useState<{ email: string; password: string }>({ email: '', password: '' });

  // Get ?flow=... from the URL
  const flowId = searchParams.get('flow');
  const returnTo = searchParams.get('return_to');
  // Refresh means we want to refresh the session. This is needed, for example, when we want to update the password
  // of a user.
  const refresh = searchParams.get('refresh');
  // AAL = Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
  // to perform two-factor authentication/verification.
  const aal = searchParams.get('aal');

  useEffect(() => {
    setIsLoading(true);
    // If we already have a flow, do nothing.
    if (flow) {
      setIsLoading(false);
      return;
    }

    // If ?flow=.. was in the URL, we fetch it
    if (flowId) {
      ory
        .getSelfServiceLoginFlow(String(flowId))
        .then(({ data }) => {
          setFlow(data);
        })
        .catch(handleGetFlowError(navigate, 'login', setFlow));
      setIsLoading(false);
      return;
    }

    // Otherwise we initialize it
    ory
      .initializeSelfServiceLoginFlowForBrowsers(
        Boolean(refresh),
        aal ? String(aal) : undefined,
        returnTo ? String(returnTo) : undefined,
      )
      .then(({ data }) => {
        setFlow(data);
      })
      .catch(handleFlowError(navigate, 'login', setFlow));
    setIsLoading(false);
  }, [flowId, aal, refresh, returnTo, flow, navigate]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setForm({ ...form, [event.target.id]: event.target.value });
  };

  const getCsrftokenFromFlow = (flow: SelfServiceLoginFlow): string => {
    const flowAttributes = flow.ui?.nodes[0]?.attributes as UiNodeInputAttributes;
    return flowAttributes?.value;
  };

  const handleSubmit = async (): Promise<void> => {
    formMessage && setFormMessage(undefined);

    if (flow) {
      setSearchParams(`/login?flow=${flow?.id}`, { replace: true });
      const values = {
        identifier: form.email,
        password: form.password,
        method: 'password',
        csrf_token: getCsrftokenFromFlow(flow),
      } as SubmitSelfServiceLoginFlowBody;

      ory
        .submitSelfServiceLoginFlow(String(flow?.id), values, undefined)
        // We logged in successfully! Let's bring the user home.
        .then(() => {
          if (flow?.return_to) {
            window.location.href = flow?.return_to;
            return;
          }
          window.location.replace(config.api.frontapplication);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(() => {})
        .catch(handleFlowError(navigate, 'login', setFlow))
        .catch((err: AxiosError) => {
          // If the previous handler did not catch the error it's most likely a form validation error
          if (err.response?.status === 400) {
            // Yup, it is!
            setFlow(err.response?.data);
            setFormMessage({
              color: 'red',
              message: 'The provided credentials are invalid, please check for spelling mistakes.',
            });
            return;
          } else if (
            err.response?.status === 401 &&
            err.response?.data?.error?.reason === 'This account was disabled.'
          ) {
            setFormMessage({
              color: 'red',
              message: 'Your account is inactive. Please contact your admin or Witsee for more information.',
            });
          }
          return Promise.reject(err);
        });
    }
  };

  useEffect(() => {
    if (form?.email && !form.email.match(mailRegex)) {
      setMailError('Please enter a valid email');
    } else {
      setMailError(undefined);
    }
  }, [form]);

  return (
    <>
      {config.disableLogin === 'true' ? (
        <Maintenance />
      ) : (
        <div className="login">
          {isloading ? (
            <Loader active />
          ) : (
            <div className="login-segment">
              <div className="login-logo">
                <Image src={`${process.env.PUBLIC_URL}/images/colored-logo.png`} size="tiny" centered></Image>
              </div>
              <Divider className="login-divider" />
              <div className="login-message">
                <h3 className="login-message-title">Log In to Witsee Studio</h3>
              </div>

              <Form className="login-form" onSubmit={handleSubmit} error={!!formMessage}>
                <Form.Field
                  id="email"
                  control={Input}
                  label="Email Address"
                  placeholder="name@email.com"
                  value={form?.email}
                  onChange={handleChange}
                  error={mailError}
                />
                <Form.Field
                  id="password"
                  control={Input}
                  label="Password"
                  placeholder="****"
                  type={showPassword ? 'text' : 'password'}
                  value={form?.password}
                  icon={
                    <Icon
                      name={showPassword ? 'eye slash outline' : 'eye'}
                      link
                      onClick={(): void => setShowPassword(!showPassword)}
                    />
                  }
                  onChange={handleChange}
                />
                <div className="login-forgot-pwd-link">
                  <Link to="/recovery">Forget password ?</Link>
                </div>
                <div className="login-continue-button">
                  <Button className="submit" type="submit" disabled={!form?.email || !form?.password || !!mailError}>
                    Login
                    <IconArrowRight height={24} width={23} />
                  </Button>
                </div>
                {formMessage && <Message color={formMessage?.color} content={formMessage?.message}></Message>}
              </Form>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Login;
